<template>
  <div>
    <div v-html="page.html"></div>

    <div class="mb-4"><required-fields-info/></div>

    <b-row v-if="page.settings.name">
      <b-form-group class="col-lg-6 col-sm-12" id="firstname-group">
        <tip-label class="mb-2" for="form-firstname" :tip="$t('PAGES.MEMBER_DETAILS.TOOLTIP_FIRSTNAME')" :asterix="req_field('firstname')">{{$t('MEMBER.FIRSTNAME')}}</tip-label>
        <b-form-input
          id="form-firstname"
          v-model="form.firstname"
          :placeholder="$t('MEMBER.FIRSTNAME')"
          :state="firstname_state"
        />
        <b-form-invalid-feedback>{{ $t('VALIDATION.REQUIRED') }}</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group class="col-lg-6 col-sm-12" id="lastname-group">
        <tip-label class="mb-2" for="form-lastname" :tip="$t('PAGES.MEMBER_DETAILS.TOOLTIP_LASTNAME')" :asterix="req_field('lastname')">{{$t('MEMBER.LASTNAME')}}</tip-label>
        <b-form-input
          id="form-lastname"
          v-model="form.lastname"
          :placeholder="$t('MEMBER.LASTNAME')"
          :state="lastname_state"
        />
        <b-form-invalid-feedback>{{ $t('VALIDATION.REQUIRED') }}</b-form-invalid-feedback>
      </b-form-group>
    </b-row>

    <b-row v-if="page.settings.company_name">
      <b-form-group class="col-lg-6 col-sm-12" id="company_name-group">
        <tip-label class="mb-2" for="form-company_name" :tip="$t('PAGES.MEMBER_DETAILS.TOOLTIP_COMPANY')">{{$t('MEMBER.COMPANY_NAME')}}</tip-label>
        <b-form-input
          id="form-company_name"
          v-model="form.company_name"
          :placeholder="$t('MEMBER.COMPANY_NAME')"
        />
      </b-form-group>
    </b-row>

    <b-row v-if="page.settings.phone || page.settings.landline">
      <b-form-group v-if="page.settings.phone" class="col-lg-6 col-sm-12" id="phone-group">
        <tip-label class="mb-2" for="form-phone" :tip="$t('PAGES.MEMBER_DETAILS.TOOLTIP_PHONE')" :asterix="req_field('phone')">{{$t('PAGES.MEMBER_DETAILS.PHONE')}}</tip-label>
        <b-form-input
          id="form-phone"
          v-model="form.phone"
          placeholder="+46701234567"
          :state="phone_state"
        />
        <b-form-invalid-feedback>{{$t('PAGES.MEMBER_DETAILS.INVALID_PHONE')}}</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group v-if="page.settings.landline" class="col-lg-6 col-sm-12" id="landline-group">
        <tip-label class="mb-2" for="form-landline" :tip="$t('PAGES.MEMBER_DETAILS.TOOLTIP_LANDLINE')" :asterix="req_field('phone')">{{$t('PAGES.MEMBER_DETAILS.LANDLINE')}}</tip-label>
        <b-form-input
          id="form-landline"
          placeholder="+468123456"
          v-model="form.landline"
          :state="landline_state"
        />
        <b-form-invalid-feedback>{{$t('PAGES.MEMBER_DETAILS.INVALID_PHONE')}}</b-form-invalid-feedback>
      </b-form-group>
    </b-row>

    <b-row v-if="page.settings.email">
      <b-form-group class="col-lg-6 col-sm-12" id="email-group">
        <tip-label class="mb-2" :tip="$t('PAGES.MEMBER_DETAILS.TOOLTIP_EMAIL')" for="form-email" :asterix="req_field('email')">{{$t('MEMBER.EMAIL')}}</tip-label>
          <b-form-input
            id="form-email"
            v-model="form.email"
            :placeholder="$t('MEMBER.EMAIL')"
            :state="email_state"
          />
          <b-form-invalid-feedback >{{$t('PAGES.MEMBER_DETAILS.INVALID_EMAIL')}}</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group class="col-lg-6 col-sm-12" id="email2-group">
        <tip-label class="mb-2" for="form-email-confirm" :tip="$t('PAGES.MEMBER_DETAILS.TOOLTIP_EMAIL2')" :asterix="req_field('email2')">{{$t('MEMBER.REPEAT_EMAIL')}}</tip-label>
          <b-form-input
            id="form-email-confirm"
            v-model="form.email2"
            :placeholder="$t('MEMBER.REPEAT_EMAIL')"
            :state="email2_state"
          />
          <b-form-invalid-feedback>{{$t('PAGES.MEMBER_DETAILS.INVALID_EMAIL2')}}</b-form-invalid-feedback>
      </b-form-group>
    </b-row>

    <b-row v-if="page.settings.password && !isRenew">
      <b-form-group class="col-lg-6 col-sm-12" id="password-group">
        <tip-label class="mb-2" for="form-password" :tip="$t('PAGES.MEMBER_DETAILS.TOOLTIP_PASSWORD')" :asterix="req_field('password')">{{$t('MEMBER.PASSWORD')}}</tip-label>
          <b-form-input
            id="form-password"
            v-model="form.password"
            type="password"
            :placeholder="$t('MEMBER.PASSWORD')"
            :state="password_state"
          />
          <b-form-invalid-feedback>{{$t('PAGES.MEMBER_DETAILS.INVALID_PASSWORD')}}</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group class="col-lg-6 col-sm-12" id="password2-group">
        <tip-label class="mb-2" for="form-password-confirm" :tip="$t('PAGES.MEMBER_DETAILS.TOOLTIP_PASSWORD2')" :asterix="req_field('password2')">{{$t('MEMBER.REPEAT_PASSWORD')}}</tip-label>
          <b-form-input
            id="form-password-confirm"
            v-model="form.password2"
            type="password"
            :placeholder="$t('MEMBER.REPEAT_PASSWORD')"
            :state="password2_state"
          />
          <b-form-invalid-feedback>{{$t('PAGES.MEMBER_DETAILS.INVALID_PASSWORD2')}}</b-form-invalid-feedback>
      </b-form-group>
    </b-row>

    <b-row v-if="page.settings.birthdate && !page.settings.international_dob">
      <b-form-group class="col-lg-6 col-sm-12" id="dob-group">
        <tip-label class="mb-2" for="form-dob" :tip="$t('PAGES.MEMBER_DETAILS.TOOLTIP_DOB')" :asterix="req_field('dob')">{{$t('MEMBER.DOB')}}</tip-label>
          <b-form-input
            id="form-dob"
            v-model="form.dob"
            :disabled="disabled_birthdate"
            :placeholder="$t('MEMBER.DOB_INFO')"
            :state="dob_state"
          />
          <b-form-invalid-feedback>{{$t('PAGES.MEMBER_DETAILS.INVALID_DOB')}}</b-form-invalid-feedback>
      </b-form-group>
    </b-row>

    <b-row v-if="page.settings.birthdate && page.settings.international_dob">
      <b-form-group class="col-lg-6 col-sm-12" id="dob-group">
        <tip-label class="mb-2" :tip="$t('PAGES.MEMBER_DETAILS.TOOLTIP_INTDOB')" :asterix="req_field('dob')">{{$t('MEMBER.DOB')}}</tip-label>
        <div class="d-flex">

          <b-form-group class="my-0" :label="$t('MEMBER.MONTH')" label-for="form-month">
            <b-form-input
              id="form-month"
              v-model="dob.m"
              v-numeric
              placeholder="MM"
              :disabled="disabled_birthdate"
              :state="dob_month_state"
            />
          </b-form-group>

          <span class="mx-1"></span>

          <b-form-group class="my-0" :label="$t('MEMBER.DAY')" label-for="form-day">
            <b-form-input
              id="form-day"
              v-model="dob.d"
              v-numeric
              placeholder="DD"
              :disabled="disabled_birthdate"
              :state="dob_day_state"
            />
          </b-form-group>

          <span class="mx-1"></span>

          <b-form-group class="my-0" :label="$t('MEMBER.YEAR')" label-for="form-year">
            <b-form-input
              id="form-year"
              v-model="dob.y"
              v-numeric
              :disabled="disabled_birthdate"
              :placeholder="$t('PAGES.MEMBER_DETAILS.YYYY')"
              :state="dob_year_state"
            />
          </b-form-group>
        </div>

        <b-form-invalid-feedback :state="valid_dob">{{$t('PAGES.MEMBER_DETAILS.INVALID_DOB')}}</b-form-invalid-feedback>

      </b-form-group>
    </b-row>

    <b-row v-if="page.settings.gender">
      <b-form-group class="col-lg-6 col-sm-12" id="gender-group">
        <tip-label class="mb-2" for="form-gender" :tip="$t('PAGES.MEMBER_DETAILS.TOOLTIP_GENDER')" :asterix="req_field('gender')">{{$t('MEMBER.PUBLIC_SEX')}}</tip-label>
          <b-form-select
            id="form-ender"
            v-model="form.gender"
            :options="genders"
            :state="gender_state"
          />
        <b-form-invalid-feedback>{{ $t('VALIDATION.REQUIRED') }}</b-form-invalid-feedback>
      </b-form-group>
    </b-row>

    <b-row v-if="page.settings.avd">
      <b-form-group class="col-lg-6 col-sm-12" id="avd-group" >
        <tip-label class="mb-2" for="form-avd" :tip="$t('PAGES.MEMBER_DETAILS.TOOLTIP_AVD')" :asterix="req_field('avd')">{{$t('MEMBER.AVD')}}</tip-label>
        <b-form-input
          id="form-avd"
          v-model="form.avd"
          placeholder="Avdelning"
          :state="avd_state"
        />
        <b-form-invalid-feedback>{{ $t('VALIDATION.REQUIRED') }}</b-form-invalid-feedback>
      </b-form-group>
    </b-row>

    <b-row v-if="page.settings.address || page.settings.zipcode">
      <b-col lg="6" v-if="page.settings.address">
        <b-form-group id="address-group">
          <tip-label class="mb-2" for="form-address" :tip="$t('PAGES.MEMBER_DETAILS.TOOLTIP_ADDRESS')" :asterix="req_field('address')">{{$t('MEMBER.ADDRESS')}}</tip-label>
          <b-form-input
            id="form-address"
            v-model="form.address"
            :placeholder="$t('MEMBER.ADDRESS')"
            :state="address_state"
          />
          <b-form-invalid-feedback>{{ $t('VALIDATION.REQUIRED') }}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col lg="6" v-if="page.settings.zipcode">
        <b-form-group style="max-width: 200px;" id="zipcode-group">
          <tip-label class="mb-2" for="form-zipcode" :tip="$t('PAGES.MEMBER_DETAILS.TOOLTIP_ZIPCODE')" :asterix="req_field('address')">{{$t('MEMBER.ZIPCODE')}}</tip-label>
          <b-form-input
            id="form-zipcode"
            v-model="form.zipcode"
            placeholder="00000"
            :state="zipcode_state"
          />
          <b-form-invalid-feedback>{{ $t('VALIDATION.REQUIRED') }}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" v-if="page.settings.zipcode">
        <b-form-group id="post-group">
          <tip-label class="mb-2" for="form-post" :tip="$t('PAGES.MEMBER_DETAILS.TOOLTIP_POST')" :asterix="req_field('address')">{{$t('MEMBER.POST')}}</tip-label>
          <b-form-input
            id="form-post"
            v-model="form.post"
            :placeholder="$t('MEMBER.POST')"
            :state="post_state"
          />
          <b-form-invalid-feedback>{{ $t('VALIDATION.REQUIRED') }}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="6" v-if="page.settings.co">
        <b-form-group id="co-group">
          <tip-label class="mb-2" for="form-co" :tip="$t('PAGES.MEMBER_DETAILS.TOOLTIP_CO')">{{$t('MEMBER.CO')}}</tip-label>
          <b-form-input
            id="form-co"
            v-model="form.co"
            :placeholder="$t('MEMBER.CO')"
            :state="co_state"
          />
          <b-form-invalid-feedback>{{$t('PAGES.MEMBER_DETAILS.INVALID_CO')}}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row v-if="page.settings.region || page.settings.commune">
      <b-col lg="6">
        <b-form-group id="input-group-region_code">
          <tip-label class="mb-2" for="form-region_code" :tip="$t('PAGES.MEMBER_DETAILS.TOOLTIP_REGION')">{{$t('MEMBER.REGION')}}</tip-label>
          <b-form-select
            id="form-region_code"
            v-model="form.region_code"
            :options="region_options"
          />
        </b-form-group>
      </b-col>

      <b-col v-if="page.settings.commune" lg="6">
        <b-form-group id="input-group-commune_code" >
          <tip-label class="mb-2" for="form-commune_code" :tip="$t('PAGES.MEMBER_DETAILS.TOOLTIP_COMMUNE')">{{$t('MEMBER.COMMUNE')}}</tip-label>
          <b-form-select
            id="form-commune_code"
            v-model="form.commune_code"
            :options="communeOptions"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row v-if="page.settings.country">
      <b-col lg="6">
        <b-form-group id="input-group-country">
          <tip-label class="mb-2" for="form-country" :tip="$t('PAGES.MEMBER_DETAILS.TOOLTIP_COUNTRY')">{{$t('MEMBER.COUNTRY')}}</tip-label>
          <b-form-select
            id="form-country"
            class="mr-sm-2 mx-0"
            v-model="form.country"
            text-field="value"
            value-field="key"
            :options="$t('COUNTRIES')"
            :state="country_state"
          />
          <b-form-invalid-feedback>{{ $t('VALIDATION.REQUIRED') }}</b-form-invalid-feedback>
        </b-form-group>
      </b-col>

    </b-row>

    <div v-if="page.settings.billing">
      <b-row class="mb-3">
        <b-col>
          <h3>{{$t('MEMBER.INVOICE_ADDRESS_INFO')}}</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-form-group
          class="col-lg-6 col-sm-12"
          id="invoice_firstname-group"
          :label="$t('MEMBER.FIRSTNAME')"
          label-for="form-invoice_firstname"
        >
          <b-form-input
            id="form-invoice_firstname"
            v-model="form.invoice_firstname"
            :placeholder="$t('MEMBER.FIRSTNAME')"
          />
        </b-form-group>

        <b-form-group
          class="col-lg-6 col-sm-12"
          id="invoice_lastname-group"
          :label="$t('MEMBER.LASTNAME')"
          label-for="form-invoice_lastname"
        >
          <b-form-input
            id="form-invoice_lastname"
            v-model="form.invoice_lastname"
            :placeholder="$t('MEMBER.LASTNAME')"
          />
        </b-form-group>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-form-group id="address-group" :label="$t('MEMBER.ADDRESS')" label-for="form-invoice_address">
            <b-form-input
              id="form-invoice_address"
              v-model="form.invoice_address"
              :placeholder="$t('MEMBER.LASTNAME')"
            />
          </b-form-group>
        </b-col>
        <b-col lg="6">
          <b-form-group
            style="max-width: 200px;"
            id="invoice_zipcode-group"
            :label="$t('MEMBER.ZIPCODE')"
            label-for="form-invoice_zipcode"
          >
            <b-form-input
              id="form-invoice_zipcode"
              placeholder="00000"
              v-model="form.invoice_zipcode"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-form-group id="invoice_post-group" :label="$t('MEMBER.POST')" label-for="form-invoice_post">
            <b-form-input
              id="form-invoice_post"
              v-model="form.invoice_post"
              :placeholder="$t('MEMBER.POST')"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6">
          <b-form-group id="invoice_country-group" :label="$t('MEMBER.COUNTRY')" label-for="form-invoice_country">
            <b-form-select
              id="form-invoice_country"
              class="mr-sm-2 mx-0"
              v-model="form.invoice_country"
              text-field="value"
              value-field="key"
              :options="$t('COUNTRIES')"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <div v-if="page.settings.invoice_email">
      <b-row>
        <b-col lg="6">
          <b-form-group style="max-width: 200px;" id="invoice_email-group" :label="$t('MEMBER.INVOICE_EMAIL')" label-for="form-invoice_email">
            <b-form-input
              id="form-invoice_email"
              v-model="form.invoice_email"
              placeholder="faktura@epost.se"
              :state="email_invoice_state"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <div v-if="page.settings.invoice_phone">
      <b-row>
        <b-col lg="6">
          <b-form-group style="max-width: 200px;" id="invoice_phone-group" :label="$t('MEMBER.INVOICE_PHONE')" label-for="form-invoice_phone">
            <b-form-input
              id="form-invoice_phone"
              v-model="form.invoice_phone"
              placeholder="023123123"
              :state="invoice_phone_state"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import validate_pers_number from '@/core/services/personnr';
import { validate_email } from '@/core/services/email';
import RequiredFieldsInfo from '@/view/components/RequiredFieldsInfo.vue';
import TipLabel from '@/view/components/TipLabel.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { useVuelidate } from '@vuelidate/core';
import { email, minLength, maxLength } from '@vuelidate/validators';
import { requiredIf, sameAs } from 'vuelidate/lib/validators';
import {
  validate_day, validate_dob,
  validate_month,
  validate_phone,
  validate_state,
  validate_year
} from '@/core/services/validations.service';

export default {
  name: 'DetailsPage',
  mixins: [ toasts ],
  props: ['page', 'spar', 'regions', 'page_data', 'isRenew', 'requirements', 'personnr'],
  emits: ['wizardPageWasUpdated', 'isAlreadyRegistered', 'update:page_data'],
  components: { TipLabel, RequiredFieldsInfo, },
  data() {
    return {
      local_page_data: {},
      checkedEmail: null,
      genders: [
        { value: 'F', text: this.$t('COMMON.WOMAN') },
        { value: 'M', text: this.$t('COMMON.MAN') },
        { value: 'O', text: this.$t('COMMON.OTHER') },
        { value: 'U', text: this.$t('COMMON.DONT_WANT') },
      ],
      form: {
        firstname: '',
        lastname: '',
        company_name: '',
        gender: '',
        dob: '',
        email: '',
        email2: '',
        phone: '',
        avd: '',
        landline: '',
        password: '',
        password2: '',
        address: '',
        zipcode: '',
        post: '',
        co: '',
        country: 'NONE',
        region_code: '',
        commune_code: '',
        invoice_firstname: '',
        invoice_lastname: '',
        invoice_address: '',
        invoice_zipcode: '',
        invoice_post: '',
        invoice_country: 'NONE',
        invoice_email: '',
        invoice_phone: ''
      },

      dob : { d: '', m: '', y: '', vd: null, vm : null, vy: null },

      communeOptions: [],
      disabled_birthdate: false
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    const validations = {
      form: {
        firstname: {
          required: requiredIf(() => this.req_field('firstname') && this.page.settings.name)
        },
        lastname: {
          required: requiredIf(() => this.req_field('lastname') && this.page.settings.name)
        },
        phone: {
          required: requiredIf(() => this.req_field('phone') && this.page.settings.phone),
          phone: requiredIf(() => this.page.settings.phone && this.req_field('phone') && validate_phone),
        },
        landline: {
          required: false,
          phone: requiredIf(() => this.page.settings.landline && this.req_field('phone') && validate_phone),
        },
        email: {
          required: requiredIf(() => this.req_field('email') && this.page.settings.email),
          email,
        },
        email2: {
          required: requiredIf(() => this.req_field('email') && this.page.settings.email),
          email,
          same_as_email: sameAs('email'),
        },
        password: {
          required: requiredIf(() => this.req_field('password') && this.page.settings.password),
          min_length: minLength(6),
          max_length: maxLength(30),
        },
        password2: {
          required: requiredIf(() => this.req_field('password') && this.page.settings.password),
          same_as_password: sameAs('password'),
        },
        dob: {
          required: requiredIf(() => !this.is_international_dob && this.req_field('dob') && this.page.settings.birthdate),
          dob: requiredIf(() => !this.is_international_dob && validate_dob && this.page.settings.birthdate),
        },
        invoice_email: {
          email,
        },
        invoice_phone: {
          phone: requiredIf(() => this.page.settings.invoice_phone && validate_phone),
        },
        country: {
          required: requiredIf(() => this.req_field('country') && this.page.settings.country),
        },
        co: {
          min_length: minLength(2),
        },
        post: {
          required: requiredIf(() => this.req_field('address') && this.page.settings.post),
        },
        zipcode: {
          required: requiredIf(() => this.req_field('address') && this.page.settings.zipcode),
        },
        address: {
          required: requiredIf(() => this.req_field('address') && this.page.settings.address),
        },
        avd: {
          required: requiredIf(() => this.req_field('avd') && this.page.settings.avd),
        },
        gender: {
          required: requiredIf(() => this.req_field('gender') && this.page.settings.public_sex),
        },
      },
      dob: {
        d: {
          required: requiredIf(() => this.is_international_dob && this.req_field('dob') && this.page.settings.birthdate),
          day: requiredIf(() => this.is_international_dob && validate_day(this.dob?.d, this.dob?.m, this.dob?.y)),
        },
        m: {
          required: requiredIf(() => this.is_international_dob && this.req_field('dob') && this.page.settings.birthdate),
          month: requiredIf(() => this.is_international_dob && validate_month(this.dob?.m, this.dob?.y)),
        },
        y: {
          required: requiredIf(() => this.is_international_dob && this.req_field('dob') && this.page.settings.birthdate),
          year: requiredIf(() => this.is_international_dob && validate_year),
        },
      }
    }
    console.log('validations', validations)
    return validations
  },
  mounted() {
    if (this.page_data) {
      this.local_page_data = { ...this.page_data };
    }
    if (this.page_data && this.page_data.data && this.page_data.data.details) {
      this.form = this.page_data.data.details;
    }

    if (this.personnr) {
      const formatted = validate_pers_number(this.personnr);

      if (formatted.success) {
        this.form.dob = formatted.birthdate;
        this.form_was_updated();
      }
    }

    this.$emit('wizardPageWasUpdated', this.page.type, this.form, this.isNextButtonOK());
  },

  created() {},

  directives: {
    numeric: {
      inserted: (el) => {
        el.oninput = (ev) => {
          const value = parseInt(ev.target.value, 10);
          el.value = isNaN(value) ? '' : value;
        };
      }
    }
  },

  computed: {
    firstname_state() {
      return validate_state({ dirty: this.v$.form?.firstname.$dirty, error: this.v$.form?.firstname.$error });
    },
    lastname_state() {
      return validate_state({ dirty: this.v$.form?.lastname.$dirty, error: this.v$.form?.lastname.$error });
    },
    phone_state() {
      return validate_state({ dirty: this.v$.form?.phone.$dirty, error: this.v$.form?.phone.$error });
    },
    landline_state() {
      return validate_state({ dirty: this.v$.form?.landline.$dirty, error: this.v$.form?.landline.$error });
    },
    email_state() {
      return validate_state({ dirty: this.v$.form?.email.$dirty, error: this.v$.form?.email.$error });
    },
    email2_state() {
      return validate_state({ dirty: this.v$.form?.email2.$dirty, error: this.v$.form?.email2.$error });
    },
    password_state() {
      return validate_state({ dirty: this.v$.form?.password.$dirty, error: this.v$.form?.password.$error });
    },
    password2_state() {
      return validate_state({ dirty: this.v$.form?.password2.$dirty, error: this.v$.form?.password2.$error });
    },
    dob_state() {
      return validate_state({ dirty: this.v$.form?.dob.$dirty, error: this.v$.form?.dob.$error });
    },
    dob_day_state() {
      const state = validate_state({ dirty: this.v$.dob?.d.$dirty, error: this.v$.dob?.d.$error });
      this.dob.vd = state;
      return state;
    },
    dob_month_state() {
      const state = validate_state({ dirty: this.v$.dob?.m.$dirty, error: this.v$.dob?.m.$error });
      this.dob.vm = state;
      console.log('dob_month_state', state)
      return state;
    },
    dob_year_state() {
      const state = validate_state({ dirty: this.v$.dob?.y.$dirty, error: this.v$.dob?.y.$error });
      this.dob.vy = state;
      return state;
    },
    valid_dob() {
      if (this.dob.vd === false || this.dob.vm === false || this.dob.vy === false) {
        return false;
      }
      return null;
    },
    gender_state() {
      return validate_state({ dirty: this.v$.form?.gender.$dirty, error: this.v$.form?.gender.$error })
    },
    avd_state() {
      return validate_state({ dirty: this.v$.form?.avd.$dirty, error: this.v$.form?.avd.$error })
    },
    address_state() {
      return validate_state({ dirty: this.v$.form?.address.$dirty, error: this.v$.form?.address.$error });
    },
    zipcode_state() {
      return validate_state({ dirty: this.v$.form?.zipcode.$dirty, error: this.v$.form?.zipcode.$error });
    },
    post_state() {
      return validate_state({ dirty: this.v$.form?.post.$dirty, error: this.v$.form?.post.$error });
    },
    co_state() {
      return validate_state({ dirty: this.v$.form?.co.$dirty, error: this.v$.form?.co.$error });
    },
    country_state() {
      return validate_state({ dirty: this.v$.form?.country.$dirty, error: this.v$.form?.country.$error });
    },
    email_invoice_state() {
      return validate_state({ dirty: this.v$.form?.invoice_email.$dirty, error: this.v$.form?.invoice_email.$error });
    },
    invoice_phone_state() {
      return validate_state({ dirty: this.v$.form?.invoice_phone.$dirty, error: this.v$.form?.invoice_phone.$error });
    },

    region_options() {
      const opts = [];

      for (const k in this.regions) {
        opts.push({ value: k, text: this.regions[k].region });
      }

      opts.sort(function(a, b) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });

      return opts;
    },
    is_international_dob() {
      return this.page.settings.birthdate && this.page.settings.international_dob
    }
  },

  watch: {
    dob: {
      handler(value) {
        this.form.dob = dayjs(value.y + '-' + value.m + '-' + value.d).format('YYYYMMDD');
      },
      deep: true
    },
    'regions': {
      handler() {
        if (!(this.form.region_code in this.regions)) {
          return;
        }

        this.form.region = this.regions[this.form.region_code].region;
        this.loadCommunes(this.form.region_code);
      },
      deep: true
    },
    form: {
      handler() {
        this.form_was_updated();
      },
      deep: true
    },
    'form.dob': function(newVal) {
      const formatted = validate_pers_number(newVal);

      if (formatted.success) {
        this.form.dob = formatted.birthdate;
        this.form_was_updated();
      }
    },
    page_data: {
      deep: true,
      handler() {
        this.local_page_data = { ...this.page_data };
      },
    },
    'page_data.data.personnr': function(newVal) {
      const formatted = validate_pers_number(newVal);
      if (formatted.success) {
        this.form.dob = formatted.birthdate;
        const date = dayjs(formatted.birthdate, 'YYYYMMDD');
        this.dob.y = date.year(); this.dob.vy = null;
        this.dob.m = date.month()+1; this.dob.vm = null;
        this.dob.d = date.date(); this.dob.vd = null;
        this.disabled_birthdate = true;
      } else {
        this.form.dob = '';
        this.disabled_birthdate = false;
      }
    },

    'page_data.data.details.firstname'() {
      this.form.firstname = this.page_data.data.details.firstname;
    },
    'page_data.data.details.lastname'() {
      this.form.lastname = this.page_data.data.details.lastname;
    },

    'form.region_code': function(newVal) {

      this.$nextTick(() => {

        if (!this.regions || !(this.form.region_code in this.regions)) {
          //console.error('watcher form.region_code regions are not initialized');
          // its ok, regions will be populated later
          return;
        }

        this.form.region = this.regions[this.form.region_code].region;
        this.loadCommunes(newVal);

        if (!this.communeOptions.find(c => c.value === this.form.commune_code)) {
          this.form.commune_code = null;
        }
      });


    },
    'form.commune_code': function(newVal) {
      if (!newVal) return;

      this.$nextTick(()=> {
        if (!this.regions || !(this.form.region_code in this.regions)) {
          //console.error('watcher form.commune_code region not initialized');
          // still ok
          return;
        }

        this.form.commune = this.regions[this.form.region_code].communes[newVal];
      });
    },
    'form.zipcode'(newValue) {
      if (newValue !== null && newValue !== undefined && (newValue + '').length === 5) {
        axios
          .get(`https://zip.memlist.se/api/v1/zip/${newValue}`)
          .then(res => {
            if (res.status === 200) {
              this.form.post = res.data.post;
              this.form.region_code = res.data.region.code;
              this.form.commune_code = res.data.commune.code;
            }
          })
          .catch(err => {});
      }
    },
    spar(newValue, oldValue) {
      if (newValue) {
        this.form.firstname = newValue.firstname || '';
        this.form.lastname = newValue.lastname || '';
        this.form.dob = newValue.birthdate || '';
        this.form.address = newValue.address || '';
        this.form.zipcode = newValue.zipcode || '';
        this.form.post = newValue.post || '';
        this.form.region_code = newValue.region_code || '';
        this.form.commune_code = newValue.commune_code || '';
        this.form.password = newValue.password || '';
      }
    }
  },

  methods: {
    async validate() {

      const res = await this.v$.$validate();

      console.log('$v', this.$v);

      return res;
    },

    clear_email() {
      this.form.email = '';
      this.form.email2 = '';
      this.checkedEmail = null;
    },

    req_field(name) {
      switch (name) {
        case 'firstname': return this.requirements.MEMBER_REQ_FULLNAME;
        case 'lastname': return this.requirements.MEMBER_REQ_FULLNAME;
        case 'email': return this.requirements.MEMBER_REQ_EMAIL;
        case 'email2': return this.requirements.MEMBER_REQ_EMAIL;
        case 'password': return this.requirements.MEMBER_REQ_PASSWORD;
        case 'password2': return this.requirements.MEMBER_REQ_PASSWORD;
        case 'phone': return this.requirements.MEMBER_REQ_PHONE;
        case 'landline': return this.requirements.MEMBER_REQ_PHONE;
        case 'dob': return this.requirements.MEMBER_REQ_PERSONNR;
        case 'address': return this.requirements.MEMBER_REQ_ADDRESS;
        case 'avd': return this.requirements.MEMBER_REQ_AVD;
        case 'zipcode': return this.requirements.MEMBER_REQ_ADDRESS;
        case 'post': return this.requirements.MEMBER_REQ_ADDRESS;
        case 'gender': return this.requirements.MEMBER_REQ_SEX;
        case 'country': return this.requirements.MEMBER_REQ_ADDRESS;
      }
      return false;
    },

    getData() {
      return {
        email: this.form.email,
        firstname: this.form.firstname,
        lastname: this.form.lastname,
        dob: this.form.dob,
        address: this.form.address,
        zipcode: this.form.zipcode,
        co: this.form.co,
        region_code: this.form.region_code,
        commune_code: this.form.commune_code,
        post: this.form.post,
        password: this.form.password,
        password2: this.form.password2,
        phone: this.form.phone,
        landline: this.form.landline,
        avd: this.form.avd,
        gender: this.form.gender,
        country: this.form.country,
        invoice_address: this.form.invoice_address,
        invoice_firstname: this.form.invoice_firstname,
        invoice_lastname: this.form.invoice_lastname,
        invoice_zipcode: this.form.invoice_zipcode,
        invoice_post: this.form.invoice_post,
        invoice_country: this.form.invoice_country,
        invoice_email: this.form.invoice_email,
        invoice_phone: this.form.invoice_phone,
      }
    },

    isNextButtonOK() {
      return true;
    },

    async form_was_updated() {
      this.local_page_data[this.page.id] = {
        ...this.page_data[this.page.id],
        form: { details: this.form },
        validation: this.v$
      };

      if (!this.isRenew && validate_email(this.form.email) && this.checkedEmail !== this.form.email) {
        this.checkedEmail = this.form.email;
        await this.check_identification(undefined, this.form.email, undefined, (is_already_registered, is_rate_limit) => {

          if (is_already_registered) {
            this.$emit('isAlreadyRegistered', this.form.email);
          }
          else {
            this.$emit('wizardPageWasUpdated', this.page.type, this.getData(), this.isNextButtonOK());

            this.$emit('update:page_data', this.local_page_data);
          }
        });

        return;
      }

      this.$emit('wizardPageWasUpdated', this.page.type, this.getData(), this.isNextButtonOK());

      this.$emit('update:page_data', this.local_page_data);
      console.log('form', this.form)
    },

    async check_identification(personnr, email, phone, callback) {
      try {
        const res = await axios.post(`/member/identification`, {
          personnr: personnr,
          email: email,
          phone: phone
        });

        if (res.status === 404) {
          callback(false, false);
        }
        else if (res.status === 200) {
          callback(true, false);
        }
        else if (res.status === 429) {
          callback(false, true);
        }
        else {
          callback(false, false);
        }
      } catch (err) {
        console.error(err);
      }
    },

    setForm(data) {
      this.form.firstname = data.firstname || '';
      this.form.lastname = data.lastname || '';
      this.form.company_name = data.company_name || '';
      this.form.email = data.email || '';
      this.form.email2 = data.email || '';
      this.form.phone = data.phone || '';
      this.form.landline = data.landline || '';
      this.form.avd = data.avd || '';
      this.form.dob = data.dob || '';
      this.form.address = data.address || '';
      this.form.zipcode = data.zipcode || '';
      this.form.post = data.post || '';
      this.form.co = data.co || '';
      this.form.password = data.password || '';
      this.form.password2 = data.password || '';
      this.form.region_code = data.region_code || '';
      this.form.commune_code = data.commune_code || '';
      this.form.country = data.country || 'NONE';
      this.form.invoice_firstname = data.invoice_firstname || '';
      this.form.invoice_lastname = data.invoice_lastname || '';
      this.form.invoice_address = data.invoice_address || '';
      this.form.invoice_zipcode = data.invoice_zipcode || '';
      this.form.invoice_post = data.invoice_post || '';
      this.form.invoice_country = data.invoice_country || 'NONE';
      this.form.invoice_email = data.invoice_email || '';
      this.form.invoice_phone = data.invoice_phone || '';
      this.form.gender = data.public_sex || data.sex || '';

      this.form_was_updated();
    },

    getAge() {
      const str = this.fmt_personnr.substring(0, 8);

      return dayjs().diff(str, 'years');
    },

    loadCommunes(selectedRegion) {
      this.communeOptions = [];

      const communes = this.regions[selectedRegion].communes;
      this.communes = communes;

      for (const k in communes) {
        this.communeOptions.push({ value: k, text: communes[k] });
      }

      this.communeOptions.sort(function(a, b) {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });
    }
  }
};
</script>
